import { APIKeysAndParameters } from "./hooks/useCache";
import { GeoIdUUIDPairs } from "./utils-and-types";
import * as t from "io-ts";

export const featureMap = t.record(
  t.string,
  t.type({
    type: t.literal("Feature"),
    properties: t.type({
      GEOID: t.string,
      // uuid: t.union([t.string, t.undefined]),
    }),
    geometry: t.type({
      type: t.union([t.literal("Polygon"), t.literal("MultiPolygon")]),
      coordinates: t.UnknownArray,
    }),
  })
);
type FeatureMap = t.TypeOf<typeof featureMap>;
export type CachedBoundaries = Record<
  string,
  FeatureMap[string] & {
    properties: {
      uuid: string;
    };
  }
>;

export function getJsonsToLoad(
  displayInfo: GeoIdUUIDPairs,
  cache: Partial<Record<string, CachedBoundaries>>
) {
  return [...new Set<string>(displayInfo.map((x) => x.jsonUUID))].filter(
    (uuid) => cache[uuid] === undefined
  );
}

export function jsonsAsAPIParams(
  uuids: string[]
): APIKeysAndParameters<"GET">[] {
  return uuids.map((uuid) => ({
    requestKey: [uuid],
    url: `/geojson/${uuid}.json`,
  }));
}

function transformBoundaries(
  requestKey: string[],
  response: FeatureMap
): CachedBoundaries {
  const result: CachedBoundaries = {};
  for (const [geoId, feature] of Object.entries(response)) {
    result[geoId] = {
      ...feature,
      properties: { ...feature.properties, uuid: requestKey[0] },
    };
  }
  return result;
}

export function mergeJsonsIntoCache(
  prevCache: Partial<Record<string, CachedBoundaries>>,
  keysAndResponses: {
    requestKey: string[];
    apiResponse: FeatureMap;
  }[]
): Partial<Record<string, CachedBoundaries>> {
  const transformedKeysAndResponses: {
    requestKey: string[];
    transformedResponse: CachedBoundaries;
  }[] = keysAndResponses.map(({ requestKey, apiResponse }) => ({
    requestKey,
    transformedResponse: transformBoundaries(requestKey, apiResponse),
  }));

  const newCacheEntries: Record<string, CachedBoundaries> = Object.fromEntries(
    transformedKeysAndResponses.map(({ requestKey, transformedResponse }) => [
      requestKey[0],
      transformedResponse,
    ])
  );
  return {
    ...prevCache,
    ...newCacheEntries,
  };
}
