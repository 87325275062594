import { WebMercatorViewport } from "react-map-gl";
import * as t from "io-ts";
import { CachedBoundaries } from "./boundariesCache";

export type MapboxBounds = number[][];

const boundingBoxType = t.type({
  northLat: t.number,
  eastLng: t.number,
  southLat: t.number,
  westLng: t.number,
});

export type BoundingBox = t.TypeOf<typeof boundingBoxType>;

const requiredBasicPlusInfo = t.type({
  approximateTractCount: t.number,
  boundingBox: boundingBoxType,
});

const optionalBasicPlusInfo = t.partial({
  name: t.string,
  geoJsonUUIDs: t.tuple([t.string, t.string]),
  children: t.array(t.string),
});

const basicPlusInfo = t.intersection([
  requiredBasicPlusInfo,
  optionalBasicPlusInfo,
]);

type BasicPlusInfo = t.TypeOf<typeof basicPlusInfo>;
//  {
//   name?: string;
//   geoJsonUUIDs?: [string, string];
//   approximateTractCount: number;
//   boundingBox: BoundingBox;
//   children?: string[];
// };

const childrenBasicInfo = t.record(t.string, basicPlusInfo);
// type ChildrenBasicInfo = t.TypeOf<typeof childrenBasicInfo>;
// type ChildrenBasicInfo = {
//   [childFIPS: string]: BasicPlusInfo;
// };

export interface MyViewport {
  latitude: number;
  longitude: number;
  zoom: number;
  bearing: number;
  pitch: number;
  width: number;
  height: number;
}

export const basicPlusResponse = t.record(t.string, childrenBasicInfo);
export type BasicPlusResponse = t.TypeOf<typeof basicPlusResponse>;
// export type BasicPlusResponse = {
//   [parentFIPS: string]: ChildrenBasicInfo;
// };
export type BasicPlusCache = Partial<Record<string, BasicPlusInfo>>;

interface GeoIDuuidPair {
  geoId: string;
  jsonUUID: string;
}

interface GeoIduuidPairWithBoundary extends GeoIDuuidPair {
  boundary: CachedBoundaries[string];
}

export type GeoIdUUIDPairs = GeoIDuuidPair[];
export type GeoJSONDisplayInfo = GeoIduuidPairWithBoundary[];

export function eqSet<T>(set1: Set<T>, set2: Set<T>) {
  if (set1.size !== set2.size) return false;
  for (const memb of set1) {
    if (!set2.has(memb)) {
      return false;
    }
  }
  return true;
}

export function assertUnreachable(x: never): never {
  console.error(x);
  throw new Error(`This should not be possible`);
}

export function getExpandedBounds(viewport: MyViewport) {
  const wmv = new WebMercatorViewport(viewport);

  // Per https://visgl.github.io/react-map-gl/docs/api-reference/web-mercator-viewport:
  // Returns [[lon, lat], [lon, lat]] as the south west and north east corners
  // of the smallest orthogonal bounds that encompasses the visible region.
  const bounds: MapboxBounds = wmv.getBounds();
  const [[westLon, southLat], [eastLon, northLat]] = bounds;
  const lonWidth = eastLon - westLon;
  const latHeight = northLat - southLat;
  const centerLon = (eastLon + westLon) / 2;
  const centerLat = (northLat + southLat) / 2;

  const expandedWidth = lonWidth * 1.5;
  const expandedHeight = latHeight * 1.5;

  const expandedBounds: MapboxBounds = [
    [centerLon - expandedWidth / 2, centerLat - expandedHeight / 2],
    [centerLon + expandedWidth / 2, centerLat + expandedHeight / 2],
  ];

  return { bounds, expandedBounds };
}

export function identityTransform<T>(requestKey: string[], x: T): T {
  return x;
}

export function stringIdentity(item: string) {
  return [item];
}
