import { assertUnreachable } from "../utils-and-types";

export type PreviouslyRequestedList =
  | Set<string>
  | Partial<{ [key: string]: PreviouslyRequestedList }>;

export function wasPreviouslyRequested(
  key: string[],
  previouslyRequested: PreviouslyRequestedList
): boolean {
  if (key.length === 0) {
    throw new Error(`Too few keys supplied: key.length === 0)`);
  }
  if (previouslyRequested instanceof Set) {
    if (key.length !== 1) {
      throw new Error(
        `Too many keys supplied: previouslyRequested instanceof Set but key.length !== 1`
      );
    }
    return previouslyRequested.has(key[0]);
  }
  if (typeof previouslyRequested !== "object" || previouslyRequested === null) {
    assertUnreachable(previouslyRequested);
    throw new Error(
      `typeof previouslyRequested !== "object" || previouslyRequested === null`
    );
  }
  const subList = previouslyRequested[key[0]];
  if (subList === undefined) {
    return false;
  }
  return wasPreviouslyRequested(key.slice(1), subList);
}

export function groupKeys(keys: string[][]): { [topKey: string]: string[][] } {
  const ret: { [topKey: string]: string[][] } = {};
  for (const key of keys) {
    const existing = ret[key[0]];
    if (existing === undefined) {
      ret[key[0]] = [key.slice(1)];
    } else {
      existing.push(key.slice(1));
    }
  }
  return ret;
}

export function addKeys(
  previouslyRequested: PreviouslyRequestedList | undefined,
  keys: string[][]
): PreviouslyRequestedList {
  if (keys.length === 0) {
    throw new Error(`No new keys`);
  }
  const keyLength = keys[0].length;
  if (keyLength === 0) {
    throw new Error(`keyLength === 0`);
  }
  if (!keys.every((key) => key.length === keyLength)) {
    throw new Error(`!keys.every(key => key.length === keyLength)`);
  }
  if (keyLength === 1) {
    const prev = previouslyRequested ?? new Set();
    if (!(prev instanceof Set)) {
      throw new Error(
        `keyLength === 1 but !(previouslyRequested instanceof Set)`
      );
    }
    const prevKeys: string[] = [...prev];
    const newKeys: string[] = keys.map((key) => key[0]);
    const newSet = new Set([...prevKeys, ...newKeys]);
    return newSet;
  }
  const groupedKeys = groupKeys(keys);
  const prev: PreviouslyRequestedList = previouslyRequested ?? {};
  if (prev instanceof Set) {
    throw new Error(`keyLength !== 1 but prev instanceof Set`);
  }
  const newList: PreviouslyRequestedList = {};
  for (const [topKey, subkeys] of Object.entries(groupedKeys)) {
    newList[topKey] = addKeys(prev[topKey], subkeys);
  }
  return {
    ...prev,
    ...newList,
  };
}
