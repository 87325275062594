import React, { useState, useEffect } from "react";
import { Layer, Source } from "react-map-gl";
import { eqSet } from "./utils-and-types";
import { GeoJSONDisplayInfo } from "./utils-and-types";

import ky from "ky";
import * as t from "io-ts";
import useCensusCache, { APIKeysAndParameters } from "./hooks/useCache";
import { useCallback } from "react";

const statsResult = t.record(t.string, t.number);
type StatsResult = t.TypeOf<typeof statsResult>;

const percentileResult = t.type({
  countyPercentiles: t.record(t.string, t.number),
  tractPercentiles: t.record(t.string, t.number),
});
type PercentileResult = t.TypeOf<typeof percentileResult>;

function areCensusStatisticsEqual(
  prevStatistic: CensusStatistic,
  nextStatistic: CensusStatistic
) {
  return (
    prevStatistic.acsYear === nextStatistic.acsYear &&
    prevStatistic.geographyVintage === nextStatistic.geographyVintage &&
    prevStatistic.statistic === nextStatistic.statistic
  );
}

function areEqual(
  prevProps: DataLayerProps,
  nextProps: DataLayerProps
): boolean {
  const prevGeoids = new Set<string>(prevProps.displayInfo.map((x) => x.geoId));
  const nextGeoids = new Set<string>(nextProps.displayInfo.map((x) => x.geoId));

  const prevUUIDs = new Set<string>(
    prevProps.displayInfo.map((x) => x.jsonUUID)
  );
  const nextUUIDs = new Set<string>(
    nextProps.displayInfo.map((x) => x.jsonUUID)
  );

  if (
    areCensusStatisticsEqual(
      prevProps.censusStatistic,
      nextProps.censusStatistic
    ) &&
    eqSet(prevGeoids, nextGeoids) &&
    eqSet(prevUUIDs, nextUUIDs)
  ) {
    // console.log("whoo skipped rerender");
    return true;
  } else {
    // console.log("bummer different features");
    // console.log(
    //   "statistics:",
    //   areCensusStatisticsEqual(
    //     prevProps.censusStatistic,
    //     nextProps.censusStatistic
    //   )
    // );
    // console.log("geoids:", eqSet(prevGeoids, nextGeoids));
    // console.log("uuids:", eqSet(prevUUIDs, nextUUIDs));
    return false;
  }
}

export type CensusStatistic = {
  geographyVintage: number;
  acsYear: number;
  statistic: string;
};
export type DataLayerProps = {
  jwt: string;
  displayInfo: GeoJSONDisplayInfo;
  censusStatistic: CensusStatistic;
};

function stringifyStatistic(stat: CensusStatistic): string {
  return `g${stat.geographyVintage}_a${stat.acsYear}_${stat.statistic}`;
}

const MAGMA_COLORS = [
  "#350f6a",
  "#5d177e",
  "#822581",
  "#a9327c",
  "#d0416f",
  "#ee5d5d",
  "#fb8861",
  "#feb57c",
  "#fde1a3",
];

function magmatize(
  percentiles: { [percentile: string]: number },
  colors: string[]
) {
  if (colors.length !== 9) {
    throw new Error("Write more code to handle this");
  }
  return colors.map((color, idx) => [
    percentiles[((idx + 1) * 10).toString()],
    color,
  ]);
}

function getStatsToLoad(
  statistic: string,
  displayInfo: GeoJSONDisplayInfo,
  cache: Partial<Record<string, StatsResult>>
) {
  return [...new Set<string>(displayInfo.map((x) => x.geoId))].filter(
    (geoId) => cache[statistic]?.[geoId] === undefined
  );
}

function geoIdsAsAPIParams(
  stat: CensusStatistic,
  geoIds: string[]
): APIKeysAndParameters<"POST">[] {
  return [
    {
      requestKey: [],
      json: {
        geoIds,
        ...stat,
      },
    },
  ];
}

function mergeStatsIntoCache(
  statistic: string,
  prevCache: Partial<Record<string, StatsResult>>,
  keysAndResponses: {
    requestKey: string[];
    apiResponse: StatsResult;
  }[]
): Partial<Record<string, StatsResult>> {
  if (keysAndResponses.length !== 1) {
    throw new Error(
      `mergeStatsIntoCache: keysAndResponses.length (${keysAndResponses.length})!== 1`
    );
  }
  const prevStats = prevCache[statistic] || {};
  return {
    ...prevCache,
    [statistic]: {
      ...prevStats,
      ...keysAndResponses[0].apiResponse,
    },
  };
}

function DataLayer(props: DataLayerProps) {
  const { jwt, displayInfo, censusStatistic } = props;
  const { acsYear, geographyVintage, statistic } = censusStatistic;
  console.log("Rendering DataLayer");

  const [percentiles, setPercentiles] = useState(
    {} as Partial<{ [statistic: string]: PercentileResult }>
  );

  useEffect(() => {
    const statAsString = stringifyStatistic({
      geographyVintage,
      acsYear,
      statistic,
    });
    if (percentiles[statAsString] === undefined) {
      (async () => {
        console.log(`loading percentiles for ${statAsString}`);
        const fetchedPercentiles = await ky
          .post("/api/data/Percentiles", {
            headers: { Authorization: jwt },
            json: {
              geographyVintage,
              acsYear,
              statistic,
            },
          })
          .json();
        if (!percentileResult.is(fetchedPercentiles)) {
          console.error(statAsString);
          console.error(fetchedPercentiles);
          throw new Error(`!percentileResult.is(fetchedPercentiles)`);
        }
        setPercentiles((prevPercentiles) => {
          return {
            ...prevPercentiles,
            [statAsString]: fetchedPercentiles,
          };
        });
      })();
    }
  }, [jwt, acsYear, geographyVintage, statistic, percentiles]);

  const statisticAsString = stringifyStatistic({
    geographyVintage,
    acsYear,
    statistic,
  });

  const getGeoIdsToLoad = useCallback(
    (
      displayInfo: GeoJSONDisplayInfo,
      cache: Partial<Record<string, StatsResult>>
    ) => getStatsToLoad(statisticAsString, displayInfo, cache),
    [statisticAsString]
  );

  const getPK = useCallback(
    (geoId: string) => [statisticAsString, geoId],
    [statisticAsString]
  );

  const getAPIParams = useCallback(
    (geoIds: string[]) =>
      geoIdsAsAPIParams(
        {
          geographyVintage,
          acsYear,
          statistic,
        },
        geoIds
      ),
    [geographyVintage, acsYear, statistic]
  );

  const mergeStats = useCallback(
    (prevCache: Partial<Record<string, StatsResult>>, response) =>
      mergeStatsIntoCache(statisticAsString, prevCache, response),
    [statisticAsString]
  );

  const stats = useCensusCache(
    jwt,
    "POST",
    "/api/data/CensusStatistic",
    displayInfo,
    getGeoIdsToLoad,
    getPK,
    getAPIParams,
    statsResult,
    mergeStats,
    null
  );

  // Unnecessary step but let's just go with it for now
  const geoidLines = {
    type: "FeatureCollection",
    features: displayInfo.map(({ boundary }) => boundary),
  };

  const statAsString = stringifyStatistic(censusStatistic);
  const statsForStatistic = stats[statAsString] ?? {};

  const geoidFill = {
    type: "FeatureCollection",
    features: geoidLines.features
      .filter(
        (x) =>
          statsForStatistic[x.properties.GEOID] !== undefined &&
          statsForStatistic[x.properties.GEOID] > 0
      )
      .map((feature) => ({
        ...feature,
        properties: {
          ...feature.properties,
          stat: statsForStatistic[feature.properties.GEOID],
        },
      })),
  };

  const percentilesForStat = percentiles[statAsString];

  return (
    <>
      <Source type="geojson" data={geoidLines as any}>
        <Layer id={"geoid-lines"} type={"line"} paint={{ "line-width": 1 }} />
      </Source>
      {percentilesForStat && (
        <Source type="geojson" data={geoidFill as any}>
          <Layer
            id={"geoid-fill"}
            type={"fill"}
            paint={{
              "fill-color": {
                property: "stat",
                stops: magmatize(
                  percentilesForStat.tractPercentiles,
                  MAGMA_COLORS
                ),
              },
              "fill-opacity": 0.5,
            }}
          />
        </Source>
      )}
    </>
  );
}

export default React.memo(DataLayer, areEqual);
