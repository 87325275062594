import { BoundingBox } from "./utils-and-types";

export function bboxToList(bbox: BoundingBox): number[] {
  // CSS order: top, right, bottom, left
  return [bbox.northLat, bbox.eastLng, bbox.southLat, bbox.westLng];
}

export function listToBBox([northLat, eastLng, southLat, westLng]: [
  number,
  number,
  number,
  number
]): BoundingBox {
  // CSS order: top, right, bottom, left
  return {
    northLat,
    eastLng,
    southLat,
    westLng,
  };
}

export function bboxToIntList(bbox: BoundingBox): number[] {
  return [
    Math.round(bbox.northLat * 1e6),
    Math.round(bbox.eastLng * 1e6),
    Math.round(bbox.southLat * 1e6),
    Math.round(bbox.westLng * 1e6),
  ];
}

export function intListToBBox([
  intNorthLat,
  intEastLng,
  intSouthLat,
  intWestLng,
]: [number, number, number, number]): BoundingBox {
  return {
    northLat: intNorthLat / 1e6,
    eastLng: intEastLng / 1e6,
    southLat: intSouthLat / 1e6,
    westLng: intWestLng / 1e6,
  };
}

export function doBoxesIntersect(
  box1: BoundingBox,
  box2: BoundingBox
): boolean {
  return (
    box1.westLng < box2.eastLng &&
    box1.eastLng > box2.westLng &&
    box1.northLat > box2.southLat &&
    box1.southLat < box2.northLat
  );
}

export function mapboxBoundsToBBox(mapboxBounds: number[][]): BoundingBox {
  return {
    westLng: mapboxBounds[0][0],
    southLat: mapboxBounds[0][1],
    eastLng: mapboxBounds[1][0],
    northLat: mapboxBounds[1][1],
  };
}

function overlapIntegral(start: number, end: number): number {
  // fancy version:
  // return (end - start)/2 + Math.max(0, end-0.25) - Math.max(0, end-0.75) - Math.max(0, start-0.25) + Math.max(0, start-0.75);

  // Alternate fancy version:
  // return (Math.sin(Math.PI*(end-0.5)) - Math.sin(Math.PI*(start-0.5)))/2
  return end - start;
}

export function estimatePercentInViewport(
  viewport: BoundingBox,
  box: BoundingBox
): number {
  const intersection: BoundingBox = {
    westLng: Math.max(viewport.westLng, box.westLng),
    southLat: Math.max(viewport.southLat, box.southLat),
    eastLng: Math.min(viewport.eastLng, box.eastLng),
    northLat: Math.min(viewport.northLat, box.northLat),
  };
  const lngStart =
    (intersection.westLng - box.westLng) / (box.eastLng - box.westLng);
  const lngEnd =
    (intersection.eastLng - box.westLng) / (box.eastLng - box.westLng);
  const latStart =
    (intersection.southLat - box.southLat) / (box.northLat - box.southLat);
  const latEnd =
    (intersection.northLat - box.southLat) / (box.northLat - box.southLat);

  return overlapIntegral(lngStart, lngEnd) * overlapIntegral(latStart, latEnd);
}
