import * as t from "io-ts";
import ky from "ky";
import { useEffect, useState } from "react";
import { assertUnreachable } from "../utils-and-types";
import {
  addKeys,
  PreviouslyRequestedList,
  wasPreviouslyRequested,
} from "./PreviouslyRequestedList";

type APIMethod = "GET" | "POST";
type APIRoute<Method extends APIMethod> = Method extends "POST" ? string : null;
export type APIKeysAndParameters<Method extends APIMethod> =
  Method extends "POST"
    ? {
        requestKey: string[];
        json: unknown;
      }
    : {
        requestKey: string[];
        url: string;
      };

async function onlyCachedGet(myCache: Cache, url: string): Promise<unknown> {
  const cachedResponse = await myCache.match(url);

  if (cachedResponse === undefined || !cachedResponse.ok) {
    return null;
  }

  return cachedResponse.json();
}

async function cachedGet(myCache: Cache, url: string): Promise<unknown> {
  // console.log(`cachegetting ${url}`);
  let cachedData = await onlyCachedGet(myCache, url);
  if (cachedData !== null) {
    // console.log(`Data for ${url} was in cache`);
    return cachedData;
  }
  // console.log(`Cache miss for ${url}, fetching`);
  await myCache.add(url);
  return onlyCachedGet(myCache, url);
}

// MainPage:
// Key = geoId
// One request for all geoIDs
// GeoID comes back in the response so no keys required for merging
// Merge is non-simple (some info goes to parent key, some info goes to making new keys)

// Datalayer (Boundaries)
// Multiple requests - one per uuid
// UUID is not included in the response
// Data is transformed before being merged in
// Merge is simple

// Datalayer (Statistics)
// Key = geoId
// One request for all GeoIDs
// Merge is simple but deep - need to remember the top level key it's going into

type StringDict<T> = Partial<Record<string, T>>;

export default function useCensusCache<
  InputConditions,
  CacheContents,
  RequestItem,
  APIResponse,
  Method extends APIMethod
>(
  jwt: string,
  httpMethod: Method,
  postRoute: APIRoute<Method>,
  inputConditions: InputConditions,
  getRequestItemsFomInputConditionsAndCurrentCache: (
    inputConditions: InputConditions,
    prevCache: StringDict<CacheContents>
  ) => RequestItem[],
  getPrimaryKeyOfRequestItem: (requestItem: RequestItem) => string[],
  convertRequestItemsToAPIParameters: (
    requestItems: RequestItem[]
  ) => APIKeysAndParameters<Method>[],
  responseChecker: t.Type<APIResponse>,
  mergeResponsesIntoCache: (
    prevCache: StringDict<CacheContents>,
    keysAndResponses: {
      requestKey: string[];
      apiResponse: APIResponse;
    }[]
  ) => StringDict<CacheContents>,
  initialCache: StringDict<CacheContents> | null
): StringDict<CacheContents> {
  const [cache, setCache] = useState(
    initialCache ?? ({} as StringDict<CacheContents>)
  );
  const [previouslyRequested, setPreviouslyRequested] = useState(
    undefined as PreviouslyRequestedList | undefined
  );

  // console.log("Running useCache hook");

  useEffect(() => {
    const randomNumber = Math.round(Math.random() * 10000);
    console.log("Running useEffect hook", randomNumber);
    // console.log(`Input conditions (${randomNumber})`);
    // console.log(inputConditions);
    const allRequestItems = getRequestItemsFomInputConditionsAndCurrentCache(
      inputConditions,
      cache
    );
    // console.log(`allRequestItems (${randomNumber}):`);
    // console.log(allRequestItems);
    // console.log(
    //   `allRequestItems.length: ${allRequestItems.length} (${randomNumber})`
    // );
    const filteredKeysAndRequestItems: {
      key: string[];
      item: RequestItem;
    }[] = [];

    for (const item of allRequestItems) {
      const key = getPrimaryKeyOfRequestItem(item);
      if (
        previouslyRequested === undefined ||
        !wasPreviouslyRequested(key, previouslyRequested)
      ) {
        filteredKeysAndRequestItems.push({
          key,
          item,
        });
      }
    }
    if (filteredKeysAndRequestItems.length > 0) {
      console.log(
        `(${randomNumber}) filteredKeysAndRequestItems`,
        filteredKeysAndRequestItems.slice(0, 5)
      );
      if (filteredKeysAndRequestItems.length > 5) {
        console.log(
          `(${randomNumber}) ...and ${
            filteredKeysAndRequestItems.length - 5
          } more`
        );
      }

      const requestKeysAndParameters = convertRequestItemsToAPIParameters(
        filteredKeysAndRequestItems.map(({ item }) => item)
      );
      // console.log(`requestKeysAndParameters: (${randomNumber})`);
      // console.log(requestKeysAndParameters);
      (async () => {
        let unparsedResponses: {
          requestKey: string[];
          response: unknown;
        }[] = [];
        switch (httpMethod) {
          case "GET":
            const myCache = await caches.open("tractasaurus");
            unparsedResponses = await Promise.all(
              (requestKeysAndParameters as APIKeysAndParameters<"GET">[]).map(
                async (kAndP) => {
                  return {
                    requestKey: kAndP.requestKey,
                    response: await cachedGet(myCache, kAndP.url),
                  };
                }
              )
            );
            break;

          case "POST":
            unparsedResponses = await Promise.all(
              (requestKeysAndParameters as APIKeysAndParameters<"POST">[]).map(
                async (kAndP) => {
                  return {
                    requestKey: kAndP.requestKey,
                    response: await ky
                      .post(postRoute as APIRoute<"POST">, {
                        headers: { Authorization: jwt },
                        json: kAndP.json,
                      })
                      .json(),
                  };
                }
              )
            );
            break;

          default:
            assertUnreachable(httpMethod);
            throw new Error("Should be impossible");
        }
        const parsedResponses: {
          requestKey: string[];
          apiResponse: APIResponse;
        }[] = unparsedResponses.map(({ requestKey, response }) => {
          if (!responseChecker.is(response)) {
            console.error(`!responseChecker.is(response)`);
            console.error(`responseChecker: ${responseChecker.name}`);
            console.error(`response: ${JSON.stringify(response)}`);
            console.error(`requestKey: ${JSON.stringify(requestKey)}`);
            throw new Error(`!responseChecker.is(response)`);
          }
          return { requestKey, apiResponse: response };
        });

        setCache((prev) => mergeResponsesIntoCache(prev, parsedResponses));
      })();
      // console.log(`Adding keys: (${randomNumber})`);
      // console.log(filteredKeysAndRequestItems.map((kAndRI) => kAndRI.key));
      setPreviouslyRequested((prev) =>
        addKeys(
          prev,
          filteredKeysAndRequestItems.map((kAndRI) => kAndRI.key)
        )
      );
    }
  }, [
    inputConditions,
    cache,
    previouslyRequested,
    jwt,
    httpMethod,
    postRoute,
    responseChecker,
    convertRequestItemsToAPIParameters,
    getPrimaryKeyOfRequestItem,
    getRequestItemsFomInputConditionsAndCurrentCache,
    mergeResponsesIntoCache,
  ]);

  return cache;
}
